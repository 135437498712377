import { styled } from '@mui/system'
import Icon from '../../atoms/icon'
import { SwiperSlide } from 'swiper/react'

const shouldForwardProp = (prop) => !['action', 'aboveTheFold'].includes(prop)

const Container = styled('div', { shouldForwardProp })`
	position: relative;
	--swiper-navigation-size: 48px;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const NavButton = styled(Icon, { shouldForwardProp })`
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(60)};
	${({ action }) => (action === 'prev' ? 'left: 18px;' : 'right: 18px;')}

	&.swiper-button-disabled {
		pointer-events: auto;
	}
	${({ theme }) => theme.breakpoints.up('sm')} {
		background-color: ${({ theme }) => theme.palette.common.white};
		border-radius: 50%;
		color: ${({ theme }) => theme.palette.primary.main};
		padding: 21px;
	}
`

const PaginationStyled = styled('div')`
	&.swiper-pagination-horizontal {
		bottom: 6px;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			left: 0;
			transform: none;
			text-align: center;
			padding: 0 20px;
		}
	}

	&.swiper-pagination-bullets {
		line-height: 0;
	}

	& .swiper-pagination-bullet {
		background-color: transparent;
		height: auto;
		margin: ${({ theme }) => theme.spacing(0, 0.5)};
		opacity: 1;
		padding: 2px;
		width: auto;
		&:before {
			background-color: ${({ theme }) => theme.palette.grey.light};
			border: 1px solid ${({ theme }) => theme.palette.grey.dark};
			border-radius: 50%;
			content: '';
			display: block;
			height: 8px;
			width: 8px;
			${({ theme }) => theme.breakpoints.up('md')} {
				height: 12px;
				width: 12px;
			}
		}
		&-active {
			cursor: default;
			&:before {
				background-color: ${({ theme }) => theme.palette.common.white};
				border-color: ${({ theme }) => theme.palette.common.black};
			}
		}
	}
`
const SwiperSlideStyled = styled(SwiperSlide, { shouldForwardProp })`
	display: ${({ aboveTheFold }) => (aboveTheFold ? 'flex' : undefined)};
`

const ScrollbarStyled = styled('div')`
	/* Edit swiper variables in css-baseline.js */
	&.swiper-scrollbar {
		background: ${({ theme }) => `var(--swiper-scrollbar-bg-color, ${theme.palette.grey.lighter})`};
		width: calc(100% - (var(--swiper-scrollbar-sides-offset, 1%) * 2));
		left: var(--swiper-scrollbar-sides-offset, 1%);
		right: var(--swiper-scrollbar-sides-offset, 1%);
		bottom: var(--swiper-scrollbar-bottom, 0px);
		z-index: 1;
		.swiper-scrollbar-drag {
			background: ${({ theme }) => `var(--swiper-scrollbar-drag-bg-color, ${theme.palette.grey.main})`};
			border-radius: ${({ theme }) => `var(--swiper-scrollbar-border-radius, ${theme.shape.borderRadius}px)`};
			cursor: grab;
		}
	}
`

export { Container, NavButton, PaginationStyled, SwiperSlideStyled, ScrollbarStyled }
